import React from "react"
import styled from "styled-components"

import { useTranslation } from "react-i18next"
import mobileBackground from "../images/bg-mobile.png"
import SEO from "../components/seo"
import Share from "../components/share"

const ThankYouPage = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language || "en"

  return (
    <>
      <SEO title={t("app.title")} />
      <Content>
        <XXXXXX>
          <Topic lang={lang}>{t("thankyou.topic")}</Topic>
          <Topic2>{t("thankyou.topic2")}</Topic2>
        </XXXXXX>
        <Share />
      </Content>
    </>
  )
}

const Topic = styled.div`
  font-size: 60px;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 50px;
    }
  }

  @media only screen and (max-width: 600px) {
    & {
      font-size: ${props => (props.lang === "en" ? "34px" : "40px")};
    }
  }
`

const Topic2 = styled.div`
  font-size: 18px;
  white-space: pre-line;
  @media only screen and (max-width: 600px) {
    & {
      font-size: 14px;
    }
  }
`

const Content = styled.div`
  margin: 0px auto;
  padding: 50px 30px 30px;
  max-width: 1360px;
  @media only screen and (max-width: 768px) {
    & {
      // padding: 50px 0px 16px;
      padding: 0px;
    }
  }
`

const XXXXXX = styled.div`
  @media only screen and (max-width: 768px) {
    & {
      background-image: url(${mobileBackground});
      background-size: contain;
      background-position-x: center;
      background-position-y: bottom;
      background-repeat: no-repeat;
      padding: 20px 16px 0px;
      height: calc(100vh - 110px);
    }
  }

  @media (max-width: 768px) and (orientation: landscape) {
    & {
      height: calc(100vw * 1.1);
    }
  }

  @media (max-width: 600px) and (orientation: portrait) {
    & {
      height: calc(100vh * 1.1);
      max-height: 500px;
    }
  }
`

export default ThankYouPage
